import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { useApiClient } from "../../hooks/useApiClient";
import { CherryPayApi } from "../../api/models";
import { CardOrderSummary } from "../../components/CardOrderSummary/CardOrderSummary";

export const CardOrders = () => {
  const { userBusinessId } = useUserContext();
  const business = useBusinessContext();
  const navigate = useNavigate();
  const [configs, setConfigs] = useState<CherryPayApi.CardProgram[]>();
  // load configuration for first time
  const apiClient = useApiClient();
  const fetchConfig = async () => {
    const result = await apiClient.getConfiguration(business.BusinessId);
    if (result.ok) {
      setConfigs(result.data?.CardPrograms);
    }
  };
  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title sx={{ paddingTop: 0 }}>
          Card Orders
        </PageHeading.Title>
      </PageHeading>
      <PageContent>
        <CardOrderSummary businessId={business.BusinessId} />
      </PageContent>
    </>
  );
};
